$(function() {
  "use strict";

  $('#migration').on('click', 'button#authenticate', function(e) {
    e.preventDefault();

    var step = $("#migration form").data("step");
    if (step === 'auth.origin')
      step = 'originAuth';
    if (step === 'auth.destination')
      step = 'destinationAuth';

    swal({
      title: 'Authenticatie',
      text: 'Bezig met het controleren van de gegevens...',
      button: false,
      closeOnClickOutside: false,
      onOpen: () => {
        swal.showLoading()
      }
    });

    var url = $('link[rel="canonical"]').attr('href');
    var medium = $('#migration .tab-pane.active').attr('id');
    var username = $('input#username_' + medium).val();
    var password = $('input#password_' + medium).val();
    var host = $('input#host_' + medium).val();
    var port = $('input#port_' + medium).val();

    var formData = {
      medium: medium,
      username: username,
      password: password,
      host: host,
      port: port
    }

    if (medium === 'custom') {
      formData.protocol = $('select#protocol_' + medium).val();
      formData.ssl = $('select#ssl_' + medium).val();
    }

    //console.log(formData);

    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }
    });

    $.ajax({
      type: 'POST',
      url: url + '/' + step,
      data: formData,
      dataType: 'json',
      closeOnClickOutside: false,
      success: function(data) {
        //console.log(JSON.parse(data));

        swal({
          icon: 'success',
          title: 'Verbonden',
          text: 'Succesvol verbonden met ' + username,
          timer: 1500,
          button: false
        });

        setTimeout(function() {
          location.reload();
        }, 1500);
      },
      error: function(data) {
        swal({
          icon: 'error',
          title: 'Mislukt',
          text: 'Het is niet gelukt om verbinding te maken met ' + username
        });
      }
    });
  });

  $('#migration').on('click', 'button#migrate', function(e) {
    e.preventDefault();

    var url = $('link[rel="canonical"]').attr('href');

    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }
    });

    $.ajax({
      type: 'POST',
      url: url + '/migrate',
      dataType: 'json',
      success: function(data) {
        location.reload();
      },
      error: function(data) {
        swal({icon: 'error', title: 'Fout', text: 'Er is iets mis gegaan tijdens de aanvraag. Vernieuw de pagina of probeer het later opnieuw.'});
      }
    });
  });

  $('#migration').on('click', 'button#cancelMigration', function(e) {
    e.preventDefault();

    var url = $('link[rel="canonical"]').attr('href');

    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }
    });

    $.ajax({
      type: 'GET',
      url: url + '/cancelMigration',
      dataType: 'json',
      success: function(data) {
        swal({icon: 'warning', title: 'Geannuleerd', text: 'De huidige migratie is succesvol geannuleerd.'});
      },
      error: function(data) {
        swal({icon: 'error', title: 'Fout', text: 'Er is iets mis gegaan tijdens de aanvraag. Vernieuw de pagina of probeer het later opnieuw.'});
      }
    });
  });

  $('#migration').on('click', 'button.setStep', function(e) {
    e.preventDefault();

    var url = $('link[rel="canonical"]').attr('href');
    var step = $(this).data("step");
    var formData = {
      step: step
    }

    if ($('.repeater').length) {
      formData['folders'] = $('.repeater').repeaterVal();
    }

    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }
    });

    $.ajax({
      type: 'GET',
      url: url + '/setStep',
      data: formData,
      dataType: 'json',
      success: function(data) {
        location.reload();
      },
      error: function(data) {
        swal({icon: 'error', title: 'Fout', text: 'Er is iets mis gegaan tijdens de aanvraag. Vernieuw de pagina of probeer het later opnieuw.'});
      }
    });
  });

  function getMigrationStatus() {
    var url = $('link[rel="canonical"]').attr('href');

    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }
    });

    $.ajax({
      type: 'GET',
      url: url + '/getMigrationStatus',
      dataType: 'json',
      success: function(data) {
        console.log(data);

        var status = data.migration_status;
        var percentage = data.migration_percentage + '%';
        var current = data.migration_current;
        var total = data.migration_total;

        $('#migration #migration #progressbar').css('width', percentage);
        $('#migration #migration #status').html(status);
        $('#migration #migration #percentage').html(percentage);
        $('#migration #migration #current').html(current);
        $('#migration #migration #total').html(total);
      },
      error: function(data) {
        swal({icon: 'error', title: 'Fout', text: 'Er is iets mis gegaan tijdens de aanvraag. Vernieuw de pagina of probeer het later opnieuw.'});
      }
    });
  }

  function migrateMessages() {
    var url = $('link[rel="canonical"]').attr('href');

    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }
    });

    $.ajax({
      type: 'GET',
      url: url + '/migrateMessages',
      dataType: 'json',
      success: function(data) {
        window.completed = true;
        swal({icon: 'success', title: 'Migratie voltooid', text: 'Alle berichten zijn overgezet naar het opgegeven e-mailadres.'});
      },
      error: function(data) {
        window.completed = true;
        swal({icon: 'error', title: 'Fout', text: 'Er is iets mis gegaan tijdens de aanvraag. Vernieuw de pagina of probeer het later opnieuw.'});
      }
    });
  }

  $(document).ready(function() {
    var step = $("#migration form").data("step");
    var url = $('link[rel="canonical"]').attr('href');

    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }
    });

    switch (step) {
      case 'folders.custom':

        swal({
          title: 'Ophalen folders',
          text: 'Bezig met het ophalen van de folders...',
          button: false,
          closeOnClickOutside: false,
          onOpen: () => {
            swal.showLoading()
          }
        });

        $.ajax({
          type: 'POST',
          url: url + '/folders',
          dataType: 'json',
          success: function(data) {
            $('#migration').removeClass('hide');
            swal.close();

            var oRet = '<select class="form-control" name="origin">';
            for (var i = 0; i < data.origin.length; i++) {
              oRet += '<option value="' + data.origin[i].name + '">' + data.origin[i].name + '</option>';
            }
            oRet += '</select>';

            var dRet = '<select class="form-control" name="destination">';
            for (var i = 0; i < data.destination.length; i++) {
              dRet += '<option value="' + data.destination[i].name + '">' + data.destination[i].name + '</option>';
            }
            dRet += '</select>';

            var ret = '<div data-repeater-item>';
            ret += '<div class="item m-b-15">';
            ret += '<div class="row">';

            ret += '<div class="col-md-5"><div id="oFolders">' + oRet + '</div></div>';
            ret += '<div class="col-md-1 text-center"><i class="ti-angle-right"></i></div>';
            ret += '<div class="col-md-5"><div id="dFolders">' + dRet + '</div></div>';
            ret += '<div class="col-md-1"><button data-repeater-delete type="button" class="btn btn-themecolor"><i class="fa fa-trash" aria-hidden="true"></i></button></div>';

            ret += '</div>';
            ret += '</div>';
            ret += '</div>';

            $('#migration #results').html(ret);
            $('.repeater').repeater();
          },
          error: function(data) {
            swal({icon: 'error', title: 'Fout', text: 'Er is iets mis gegaan tijdens de aanvraag. Vernieuw de pagina of probeer het later opnieuw.'});
          }
        });
        break;

      case 'migration.custom':
        swal({
          title: 'Migratie starten',
          text: 'Bezig met het staten van de migratie...',
          button: false,
          closeOnClickOutside: false,
          onOpen: () => {
            swal.showLoading()
          }
        });

        $.ajax({
          type: 'POST',
          url: url + '/migrationCustom',
          dataType: 'json',
          success: function(data) {
            console.log(data);
            $('#migration').removeClass('hide');
            swal.close();

            setInterval(function() {
              if (window.completed !== true)
                getMigrationStatus();
              }
            , 1000);

            migrateMessages();

          },
          error: function(data) {
            swal({icon: 'error', title: 'Fout', text: 'Er is iets mis gegaan tijdens de aanvraag. Vernieuw de pagina of probeer het later opnieuw.'});
          }
        });
        break;

      case 'migration.complete':
        swal({
          title: 'Migratie starten',
          text: 'Bezig met het staten van de migratie...',
          button: false,
          closeOnClickOutside: false,
          onOpen: () => {
            swal.showLoading()
          }
        });

        $.ajax({
          type: 'POST',
          url: url + '/migrationComplete',
          dataType: 'json',
          success: function(data) {
            console.log(data);
            $('#migration').removeClass('hide');
            swal.close();

            setInterval(function() {
              if (window.completed !== true)
                getMigrationStatus();
              }
            , 500);

            migrateMessages();

          },
          error: function(data) {
            swal({icon: 'error', title: 'Fout', text: 'Er is iets mis gegaan tijdens de aanvraag. Vernieuw de pagina of probeer het later opnieuw.'});
          }
        });

        break;

      default:
        break;

    }
  });

  $(document).ready(function() {
    if ($('div.migration_content').length) {
      var logs = $('div.ax_logs');
      logs.scrollTop(logs[0].scrollHeight);

      if($('div.migration_content').data("status") === 0 || $('div.migration_content').data("status") === 1) {
        var token = $("div.migration_content").data("token");
        var url = $('link[rel="canonical"]').attr('href');

        $.ajaxSetup({
          headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
          }
        });

        setInterval(function() {
          $.ajax({
            type: 'GET',
            url: '/migraties/getMigrationProgress/' + token,
            dataType: 'json',
            success: function(data) {
              console.log(data);

              if(data.status) $('span.ax_status').html(data.status);
              if(data.total) $('span.ax_total').html(data.total);
              if(data.total) $('span.ax_total_size').html(data.total_size);
              if(data.processed) $('span.ax_processed').html(data.processed);
              if(data.processed_size) $('span.ax_processed_size').html(data.processed_size);
              if(data.duplicates) $('span.ax_duplicates').html(data.duplicates);
              if(data.avg_speed) $('span.ax_avg_speed').html(data.avg_speed);
              if(data.avg_speed) $('span.ax_elapsed_time').html(data.elapsed_time);

              var ax_logs = $('div.ax_logs').html();
              if(ax_logs !== data.logs) {
                if(data.logs) $('div.ax_logs').html(data.logs);
                logs.scrollTop(logs[0].scrollHeight);
              }

              if(data.processed && data.total) {
                var percentage = Math.floor(data.processed / data.total * 100);
                $('span.ax_percentage').html(percentage);
                $('div#progressbar').css('width', percentage + '%');

                if(data.processed === data.total && data.total > 0) location.reload();
              }

              // if(data.processed_size) {
              //   var started_at = new Date(data.started_at);
              //   var total = data.total_size;
              //   var processed_bytes = data.processed_size;
              //
              //   if(processed_bytes > 0) {
              //     var seconds_elapsed = ( new Date().getTime() - started_at.getTime() ) / 1000;
              //     var bytes_per_second =  seconds_elapsed ? processed_bytes / seconds_elapsed : 0;
              //     var Kbytes_per_second = bytes_per_second / 1000 ;
              //     var remaining_bytes = total - processed_bytes;
              //     var time_remaining = seconds_elapsed ? remaining_bytes / bytes_per_second : 'calculating';
              //     var time_remaining_pretty = secondstotime(time_remaining);
              //
              //     $('span.ax_time_remaining').html(time_remaining_pretty);
              //   }
              // }
            },
            error: function(data) {
              console.log(data);
            }
          });
        }, 1000);
      }
    }

    if ($('div.migration_overview').length) {
        var token = $("div.migration_content").data("token");
        var url = $('link[rel="canonical"]').attr('href');

        $.ajaxSetup({
          headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
          }
        });

        setInterval(function() {
          $.ajax({
            type: 'GET',
            url: '/migraties/getMigrationProgressOverview',
            dataType: 'json',
            success: function(data) {
              //console.log(data);

              $.each(data, function(index, value) {
                $('tr#' + value.id +  ' span.total').html(value.total);
                $('tr#' + value.id +  ' span.processed').html(value.processed);
                $('tr#' + value.id +  ' span.processed_size').html(value.processed_size);
                $('tr#' + value.id +  ' div.progress-bar').css('width', value.percentage + '%');
              });
            },
            error: function(data) {
              console.log(data);
            }
          });
        }, 1000);
      }

      function secondstotime(secs) {
        var t = new Date(1970,0,1);
        t.setSeconds(secs);
        var s = t.toTimeString().substr(0,8);
        if(secs > 86399)
            s = Math.floor((t - Date.parse("1/1/70")) / 3600000) + s.substr(2);
        return s;
      }

  });
});
