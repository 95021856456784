$(function() {
    "use strict";
    $(function() {
        $(".preloader").fadeOut();
    });
    jQuery(document).on('click', '.mega-dropdown', function(e) {
        e.stopPropagation()
    });
    // ==============================================================
    // This is for the top header part and sidebar part
    // ==============================================================
    var set = function() {
        var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
        var topOffset = 70;
        if (width < 1170) {
            $("body").addClass("mini-sidebar");
            $('.navbar-brand span').hide();
            $(".sidebartoggler i").addClass("ti-menu");
        } else {
            $("body").removeClass("mini-sidebar");
            $('.navbar-brand span').show();
            $(".sidebartoggler i").removeClass("ti-menu");
        }

        var height = ((window.innerHeight > 0) ? window.innerHeight : this.screen.height) - 1;
        height = height - topOffset;
        if (height < 1) height = 1;
        if (height > topOffset) {
            $(".page-wrapper").css("min-height", (height) + "px");
        }

    };
    $(window).ready(set);
    $(window).on("resize", set);
    // ==============================================================
    // Theme options
    // ==============================================================
    $(".sidebartoggler").on('click', function() {
        if ($("body").hasClass("mini-sidebar")) {
            $("body").trigger("resize");
            $(".scroll-sidebar, .slimScrollDiv").css("overflow", "hidden").parent().css("overflow", "visible");
            $("body").removeClass("mini-sidebar");
            $('.navbar-brand span').show();
            $(".sidebartoggler i").addClass("ti-menu");
        } else {
            $("body").trigger("resize");
            $(".scroll-sidebar, .slimScrollDiv").css("overflow-x", "visible").parent().css("overflow", "visible");
            $("body").addClass("mini-sidebar");
            $('.navbar-brand span').hide();
            $(".sidebartoggler i").removeClass("ti-menu");
        }
    });
    // topbar stickey on scroll

    $(".fix-header .topbar").stick_in_parent({});


    // this is for close icon when navigation open in mobile view
    $(".nav-toggler").click(function() {
        $("body").toggleClass("show-sidebar");
        $(".nav-toggler i").toggleClass("mdi mdi-menu");
        $(".nav-toggler i").addClass("mdi mdi-close");
    });

    $(".search-box a, .search-box .app-search .srh-btn").on('click', function() {
        $(".app-search").toggle(200);
    });
    // ==============================================================
    // Right sidebar options
    // ==============================================================
    $(".right-side-toggle").click(function() {
        $(".right-sidebar").slideDown(50);
        $(".right-sidebar").toggleClass("shw-rside");
    });

    $('.floating-labels .form-control').on('focus blur', function(e) {
        $(this).parents('.form-group').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');

    // ==============================================================
    // Auto select left navbar
    // ==============================================================
    $(function() {
        var url = window.location;
        var element = $('ul#sidebarnav a').filter(function() {
            return this.href == url;
        }).addClass('active').parent().addClass('active');
        while (true) {
            if (element.is('li')) {
                element = element.parent().addClass('in').parent().addClass('active');
            } else {
                break;
            }
        }

    });
    // ==============================================================
    //tooltip
    // ==============================================================
    $(function() {
        $('[data-toggle="tooltip"]').tooltip()
    })
    // ==============================================================
    //Popover
    // ==============================================================
    $(function() {
        $('[data-toggle="popover"]').popover()
    })
    // ==============================================================
    // Sidebarmenu
    // ==============================================================
    // $(function() {
    //     $('#sidebarnav').metisMenu();
    // });

    // ==============================================================
    // Slimscrollbars
    // ==============================================================

    $('.message-center').slimScroll({
        position: 'right',
        size: "5px"

            ,
        color: '#dcdcdc'
    });


    $('.aboutscroll').slimScroll({
        position: 'right',
        size: "5px",
        height: '80',
        color: '#dcdcdc'
    });
    $('.message-scroll').slimScroll({
        position: 'right',
        size: "5px",
        height: '570',
        color: '#dcdcdc'
    });
    $('.chat-box').slimScroll({
        position: 'right',
        size: "5px",
        height: '470',
        color: '#dcdcdc'
    });

    $('.slimscrollright').slimScroll({
        height: '100%',
        position: 'right',
        size: "5px",
        color: '#dcdcdc'
    });

    // ==============================================================
    // Resize all elements
    // ==============================================================
    $("body").trigger("resize");
    // ==============================================================
    // To do list
    // ==============================================================
    $(".list-task li label").click(function() {
        $(this).toggleClass("task-done");
    });

    // ==============================================================
    // Login and Recover Password
    // ==============================================================
    $('#to-recover').on("click", function() {
        $("#loginform").slideUp();
        $("#recoverform").fadeIn();
    });

    // ==============================================================
    // Collapsable cards
    // ==============================================================
    $('a[data-action="collapse"]').on('click', function(e) {
        e.preventDefault();
        $(this).closest('.card').find('[data-action="collapse"] i').toggleClass('ti-minus ti-plus');
        $(this).closest('.card').children('.card-body').collapse('toggle');

    });
    // Toggle fullscreen
    $('a[data-action="expand"]').on('click', function(e) {
        e.preventDefault();
        $(this).closest('.card').find('[data-action="expand"] i').toggleClass('mdi-arrow-expand mdi-arrow-compress');
        $(this).closest('.card').toggleClass('card-fullscreen');
    });

    // Close Card
    $('a[data-action="close"]').on('click', function() {
        $(this).closest('.card').removeClass().slideUp('fast');
    });


   //  // ==============================================================
   //  // sparkline chart
   //  // ==============================================================
   //  var sparklineLogin = function() {
   //
   //      $('.spark-count').sparkline([4, 5, 0, 10, 9, 12, 4, 9, 4, 5, 3, 10, 9, 12, 10, 9, 12, 4, 9], {
   //          type: 'bar'
   //          , width: '100%'
   //          , height: '70'
   //          , barWidth: '2'
   //          , resize: true
   //          , barSpacing: '6'
   //          , barColor: 'rgba(255, 255, 255, 0.3)'
   //      });
   //
   //      $('.spark-count2').sparkline([4, 5, 0, 10, 9, 12, 4, 9, 4, 5, 3, 10, 9, 12, 10, 9, 12, 4, 9], {
   //          type: 'bar'
   //          , width: '100%'
   //          , height: '70'
   //          , barWidth: '2'
   //          , resize: true
   //          , barSpacing: '6'
   //          , barColor: 'rgba(255, 255, 255, 0.3)'
   //      });
   //
   //      $('#spark8').sparkline([ 4, 5, 0, 10, 9, 12, 4, 9], {
   //          type: 'bar',
   //          width: '100%',
   //          height: '40',
   //          barWidth: '4',
   //          resize: true,
   //          barSpacing: '5',
   //          barColor: '#26c6da'
   //      });
   //       $('#spark9').sparkline([ 0, 5, 6, 10, 9, 12, 4, 9], {
   //          type: 'bar',
   //          width: '100%',
   //          height: '40',
   //          barWidth: '4',
   //          resize: true,
   //          barSpacing: '5',
   //          barColor: '#ef5350'
   //      });
   //        $('#spark10').sparkline([ 0, 5, 6, 10, 9, 12, 4, 9], {
   //          type: 'bar',
   //          width: '100%',
   //          height: '40',
   //          barWidth: '4',
   //          resize: true,
   //          barSpacing: '5',
   //          barColor: '#7460ee'
   //      });
   //
   // }

    // var sparkResize;
    //
    // $(window).resize(function(e) {
    //   clearTimeout(sparkResize);
    //   sparkResize = setTimeout(sparklineLogin, 500);
    // });
    // sparklineLogin();


    // $(document).ready(function() {
    //   var Switchery = require('switchery');
    //   var elem = document.querySelector('.js-switch');
    //   new Switchery(elem, { size: 'small' });
    // });


});
